import { useRef, useState } from 'react';
import { TextField, Grid, Button, CircularProgress } from '@mui/material';
import { getListRecentPhotoInstagram, url } from 'services';
import './App.css'
import FileSaver from 'file-saver';

const zip = require('jszip')()

function App() {
	const usernameRef = useRef()
	const numberRef = useRef()
	const [listImg, setListImg] = useState([])
	const [isLoading, setIsLoading] = useState(false)

	const handleGetListPhoto = async (e) => {
		e.preventDefault()
		const username = usernameRef.current.value
		const size = numberRef.current.value
		if (username !== "" && size !== "") {
			const dataReq = {
				username: usernameRef.current.value,
				size: numberRef.current.value,
			}
			try {
				setIsLoading(true)
				const resPhoto = await getListRecentPhotoInstagram(dataReq)
				setIsLoading(false)
				if (resPhoto?.data?.message) {
					setListImg(resPhoto.data.message)
				}
			}
			catch (error) {
				setIsLoading(false)
			}
		}
	}

	const handleDownloadAll = () => {
		const listImgEL = document.querySelectorAll('img')
		listImgEL.forEach((item, index) => {
			const canvas = document.createElement("canvas")
			const ctx = canvas.getContext("2d")
			canvas.width = item.naturalWidth;
			canvas.height = item.naturalHeight
			ctx.drawImage(item, 0, 0)
			let base64Data = canvas.toDataURL("image/jpeg")
			base64Data = base64Data.replace(/^data:image\/(png|jpg|jpeg);base64,/, "")

			zip.file(`${index}.jpeg`, base64Data, { base64: true });
		})
		zip.generateAsync({ type: "blob" }).then(function (content) {
			return FileSaver.saveAs(content, "export.zip");
		});
	}

	return (
		<div className="App" >
			<form onSubmit={handleGetListPhoto}>

				<Grid container spacing={2} justifyContent="center">
					<Grid item md={4}>
						<TextField fullWidth label="USERNAME" inputRef={usernameRef} />
					</Grid>
					<Grid item md={4}>
						<TextField fullWidth label="Số lượng ảnh" inputRef={numberRef} defaultValue={10} />
					</Grid>
					<Grid item md={12}>
						<Button type='submit' style={{ marginBottom: "1em" }} variant='contained' onClick={handleGetListPhoto}>
							GET
						</Button>
						{listImg.length > 0 &&
							<Button style={{ marginBottom: "1em", marginLeft: "1em" }} color="error" variant='contained' onClick={handleDownloadAll}>
								DOWNLOAD ALL
							</Button>
						}
					</Grid>
				</Grid>

			</form>
			<Grid container spacing={2}>

				{isLoading ?
					<Grid item md={12}>
						<div style={{ textAlign: "center" }}>
							<CircularProgress />
						</div>
					</Grid>
					: listImg.map((item, index) =>
						<Grid item md={4} key={index}>
							<img style={{ width: "100%" }} crossOrigin="anonymous" src={`${url}/cors/${item}`} alt="" />
						</Grid>
					)}
			</Grid>
		</div>
	);
}

export default App;
