const { default: axios } = require("axios")

export const url = 'https://api.insta.ruoigiam.com'

export const getListRecentPhotoInstagram = (data) => {
    return axios({
        method: "POST",
        data,
        url: `${url}/recentPhoto`
    })
}